if(document.getElementsByClassName('GrowerPage').length > 0) {

    document.addEventListener("DOMContentLoaded", function () {
        const applyGrowerButtonAbove = document.querySelector("#apply-grower-btn-above");
        const applyGrowerButtonBelow = document.querySelector("#apply-grower-btn-below");
        const applyGrowerButtonHeader = document.querySelector("#btn-apply");

        function scrollToElement(element, offset) {
            const topPosition = element.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth"
            });
        }

        applyGrowerButtonAbove?.addEventListener("click", () => {
            const applyFormTitle = document.querySelector("#apply-now-form");
            const offset = 130;
            scrollToElement(applyFormTitle, offset);
        });

        applyGrowerButtonBelow?.addEventListener("click", () => {
            const applyFormTitle = document.querySelector("#apply-now-form");
            const offset = 150;
            scrollToElement(applyFormTitle, offset);
        });

        applyGrowerButtonHeader?.addEventListener("click", () => {
            const applyFormTitle = document.querySelector("#apply-now-form");
            const offset = 130;
            scrollToElement(applyFormTitle, offset);
        });

    });


	const applicationForm = document.querySelector("#apply-now-form");

	applicationForm.querySelector("select[name=location-state]")?.addEventListener("change", event => {
        let city = document.querySelector("[name=location-city]");
        document.querySelector("[name=location-none]")?.classList.toggle("hidden", event.target.value !== "none");
        city.classList.toggle("hidden", event.target.value === "none");
        city.required = event.target.value !== "none";
	});
	
	applicationForm.querySelector("select[name=has-experience]")?.addEventListener("change", event => {
        let experience = document.querySelector("[name=experience]");
        
        experience.classList.toggle("hidden", event.target.value === "no");
        experience.required = event.target.value === "yes";
	});

	applicationForm.querySelector("select[name=has-acreage]")?.addEventListener("change", event => {
        let experience = document.querySelector("[name=address]");
        
        experience.classList.toggle("hidden", event.target.value === "no");
        experience.required = event.target.value === "yes";
	});

    applicationForm.querySelector("input[name=acreage-information]")?.addEventListener("blur", event => {     
        let acreage = event.target.value;
        let acreageMessage = document.querySelector(".acreage-message");
        if(acreage < 10) {
            acreageMessage.style.display = "block";
        } else {
            acreageMessage.style.display = "none";
        }
        
    });

    document.getElementById('apply-submit-btn').addEventListener('click', function() {
        let checkboxes = document.querySelectorAll('input.referral:checked');

        if(checkboxes.length == 0) {
            applicationForm.querySelector(".referral-error-message").classList.add('show');
            applicationForm.classList.add("error");
            return false;
        } else {
            applicationForm.classList.remove("error");
            applicationForm.querySelector(".referral-error-message").classList.remove('show');
        }
        
    });

	applicationForm.addEventListener("submit", event => {
		event.preventDefault();
		event.stopPropagation();

		const formData = new FormData(event.target);

		applicationForm.classList.add("locked");

		fetch(applicationForm.action, {
			method: "POST",
			body: formData
		})
		.then(response => response.json())
		.then((payload) => {
			if (payload.success) {
				// applicationForm.classList.add("success");
				// applicationForm.querySelector(".success-message").textContent = payload.message;
                applicationForm.style.display = "none";
                document.querySelector(".success").style.display = "block";
            }
			else {
				applicationForm.classList.remove("locked");
				applicationForm.classList.add("error");
				applicationForm.querySelector(".error-message").textContent = payload.message;
			}
		});

		return false;
	});

}